var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading && _vm.isFirstLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"items":_vm.data.data,"lazy":true,"loading":_vm.api.isLoading,"options":_vm.options,"items-per-page:sync":_vm.options.itemsPerPage,"headers":_vm.dataHeader,"server-items-length":_vm.data.total,"sort-desc:sync":_vm.options.sortDesc,"sort-by":_vm.options.sortBy,"footer-props":{
            itemsPerPageOptions:[20,40,100],
            showCurrentPage:true,
        },"dense":""},on:{"update:options":[function($event){_vm.options=$event},_vm.onPaginationUpdate],"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(((_vm.options.page-1) * _vm.options.itemsPerPage) + (_vm.data.data.indexOf(item)+1))+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"text-h4 pt-4",attrs:{"flat":"","height":"auto"}},[_c('v-col',{staticClass:"mx-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._v(" Marketing Report ")]),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"mt-2"},[_c('v-autocomplete',{attrs:{"item-text":"coupon","item-value":"coupon","items":_vm.coupons,"label":"Filter Coupon","dense":"","outlined":"","clearable":""},model:{value:(_vm.options.couponSearch),callback:function ($$v) {_vm.$set(_vm.options, "couponSearch", $$v)},expression:"options.couponSearch"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"dense":"","label":"Search","outlined":"","clearable":""},on:{"input":_vm.handleSearchInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1)]},proxy:true},{key:"item.user_status",fn:function(ref){
        var item = ref.item;
return [(item.user_status==1)?_c('span',{staticClass:"green--text"},[_vm._v(" Verified ")]):_c('span',{staticClass:"red--text"},[_vm._v(" Not Verified ")])]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [(item.created_at!=null)?_c('span',[_vm._v(" "+_vm._s(_vm.convertTimeZone(item.created_at))+" ")]):_c('span',[_vm._v(" - ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }