<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else 
            :items="data.data"
            :lazy="true"
            :loading="api.isLoading"
            :options.sync="options"
            :items-per-page:sync="options.itemsPerPage"
            :headers="dataHeader"
            :server-items-length="data.total"
            :sort-desc:sync="options.sortDesc"
            :sort-by.sync="options.sortBy"
            :footer-props="{
                itemsPerPageOptions:[20,40,100],
                showCurrentPage:true,
            }"
            dense 
            @update:options="onPaginationUpdate"
        >
            <template
                v-slot:item.index="{item}">
                <span>
                    {{ ((options.page-1) * options.itemsPerPage) + (data.data.indexOf(item)+1) }}
                </span>
            </template>

            <template
                v-slot:top> 
                <v-toolbar
                    flat class="text-h4 pt-4"
                    height="auto">
                    <v-col
                        class="mx-4">
                        <v-row
                            no-gutters>
                            Marketing Report
                        </v-row>
                        <v-row class="pt-3">
                            <v-col
                                class="mt-2">
                                <v-autocomplete
                                item-text="coupon"
                                v-model="options.couponSearch"
                                item-value="coupon"
                                :items="coupons"
                                label="Filter Coupon"
                                dense
                                outlined
                                clearable
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="pt-0">
                                <v-text-field
                                    v-model="search"
                                    dense 
                                    label="Search"
                                    outlined 
                                    @input="handleSearchInput"
                                    clearable>

                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-toolbar>
            </template>

            <template
                v-slot:item.user_status="{item}">
                <span
                    class="green--text"
                    v-if="item.user_status==1">
                    Verified 
                </span>
                <span
                    class="red--text"
                    v-else>
                    Not Verified
                </span>
            </template>

            <template
                v-slot:item.created_at="{item}">
                <span
                    v-if="item.created_at!=null">
                    {{ convertTimeZone(item.created_at) }}
                </span>
                <span 
                    v-else>
                    -
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isFirstLoading:true,
        data:null,
        coupons:null,
        isProcessingSearch:false,
        search:"",
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            couponSearch:'',
        },
        dataHeader:[
            {
                text:'Company id',
                value:'company_id',
            },
            {
                text:'Company name',
                value:'company_name',
            },
            {
                text:'Coupon',
                value:'coupon',
            },
            {
                text:'First name',
                value:'fname',
            },
            {
                text:'Last name',
                value:'lname',
            },
            {
                text:'Email',
                value:'email',
            },
            {
                text:'Mobile',
                value:'mobile',
            },
            {
                text:'Status',
                value:'user_status',
            },
            {
                text:'Created Date',
                value:'created_at'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getMarketingReport"){
                this.data = resp.data;
                this.isFirstLoading = false;
            }
            if(resp.class==="getAllAvailableCoupon"){
                this.coupons = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchMarketingReportApi = this.fetchMarketingReport();
            let fetchAvailableCouponApi = this.fetchAvailableCoupon();
            if(this.isFirstLoading){
                this.$api.fetch(fetchAvailableCouponApi);
            }
            this.$api.fetch(fetchMarketingReportApi);
        },
        fetchMarketingReport(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/report/marketing?page="+this.options.page+"&sortBy="+this.options.sortBy+"&sortDesc="+this.options.sortDesc+"&search="+this.options.search+"&rowsPerPage="+this.options.itemsPerPage+"&couponSearch="+this.options.couponSearch;
            return tempApi;
        },
        fetchAvailableCoupon(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/coupons/available";
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        handleSearchInput(){
            setTimeout(()=>{
                this.options.search = this.search;
            },1000);
        },
        onPaginationUpdate(pagination){

            if(this.isFirstLoading){
                this.isFirstLoading = false;
            }
            else{
                this.options = this.$_.clone(pagination);
                this.fetch();
            }
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
        clearInput(){
            this.search="";
        }
    }
}
</script>